import { Injectable } from '@angular/core';
import { Constants } from '../../constants/constant';
import { CartInterface } from '../../interface/cart-interface/cart-interface';
import { ManagerService } from '../manager.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor(private serviceManagerService: ManagerService) { }

  AddToCart(cartItem: CartInterface) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Cart/AddCartItem`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_POST_IMAGE(API_URL, cartItem).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  GetCartList() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Cart/GetCartList`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  Checkout(paymentType:string){
    let API_URL = `${Constants.REST_API_MAIN}/api/Cart/Checkout?paymentType=${paymentType}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  BuyCourseFree(subjectID:string,teacherID:string){
    let API_URL = `${Constants.REST_API_MAIN}/api/Cart/BuyCourseFree?subjectID=${subjectID}&teacherID=${teacherID}`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  BuyCourseTicket(data:any){
    let API_URL = `${Constants.REST_API_MAIN}/api/Cart/BuyCourseTicket`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL,data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  RemoveCartItem(cartId:string){
    let API_URL = `${Constants.REST_API_MAIN}/api/Cart/RemoveItemCart?CartId=`+cartId;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }
  
  CheckIdOmese(omeseId:string){
    let API_URL = `${Constants.REST_API_MAIN}/api/Cart/CheckIdOmese?omeseId=`+omeseId;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  uploadFiles(files: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/UploadMedia/uploadFile`;
    return new Promise<any>((resolve, reject) => {
      this.serviceManagerService.HTTP_POST_IMAGE(API_URL, files).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  InsertTransfer(data:any){
    let API_URL = `${Constants.REST_API_MAIN}/api/Transfer/InsertTransfer`;
    return new Promise((resolve, reject) => {
      this.serviceManagerService.HTTP_POST(API_URL,data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

}
