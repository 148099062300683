import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SigninInterface } from '../interface/auth-interface/signin-interface';

@Injectable({
  providedIn: 'root'
})
export class ManagerService {

  httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
  httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })

  };

  JWT_Token?: string;

  headers = new HttpHeaders()
  .set('Content-Type', 'application/json')

  signinInterface : SigninInterface;
  
  constructor(private httpClient: HttpClient) { 
    this.signinInterface = JSON.parse(localStorage.getItem('currentUserLogin'));
    if( this.signinInterface != null){
      this.JWT_Token = this.signinInterface.token;
    }
  }

  HTTP_GET(API_URL: string) {
      return new Promise((resolve, reject) => {
          this.httpClient.get(API_URL, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `bearer ${this.JWT_Token}` }), responseType: "json" })
              .subscribe(data => {
                  resolve(data);
              },
                  error => {
                      reject(error);
                  });
      });
  }

  HTTP_POST(API_URL: string,data: any) {
      return new Promise((resolve, reject) => {
        this.httpClient.post(API_URL, data, { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `bearer ${this.JWT_Token}` }), responseType: "json" })
          .subscribe(data => {
            resolve(data);
          },
            error => {
              reject(error);
            });
      });
    }

    HTTP_POST_IMAGE(API_URL: string,data: any) {
      return new Promise((resolve, reject) => {
        this.httpClient.post(API_URL, data, { headers: new HttpHeaders({ 'Authorization': `bearer ${this.JWT_Token}` }), responseType: "json" })
          .subscribe(data => {
            resolve(data);
          },
            error => {
              reject(error);
            });
      });
    }

    HTTP_POST_AUTH(API_URL: string,data: any) {
      return new Promise((resolve, reject) => {
        this.httpClient.post(API_URL, data, { 'headers': this.headers, responseType: "json" })
          .subscribe(data => {
            resolve(data);
          },
            error => {
              reject(error);
            });
      });
    }

}
