<div class="home-slides" *ngIf="imgBanner.length > 0">
  <owl-carousel-o [options]="homeSlides">
    <ng-template carouselSlide *ngFor="let item of imgBanner">
      <div class="main-banner" style=" height: 80vh;">
        <div>
          <img src="{{item}}" alt="image" style=" height: 80vh;">
        </div>
        <div class="d-table">
          <div class="d-table-cell">
            <div class="container">
              <!-- <div class="main-banner-content">
                <span class="sub-title">ความรู้คืออาวุธที่ดีที่สุด</span>
                <h1>Learning Management System</h1>
                <p>การศึกษาเป็นทางเลือกที่เหมาะสมที่สุดสำหรับองค์กร ธุรกิจของคุณ
                  และระบบการศึกษาออนไลน์ของคุณ สร้าง
                  หลักสูตรออนไลน์ของคุณตอนนี้มีเทมเพลตหน้า ตัวเลือกสี
                  และคุณสมบัติเมนูไม่จำกัด</p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </owl-carousel-o>
</div>
<!-- <div class="row"> -->
<div style="text-align: center;
    margin-top: 10px;margin-bottom: 10px;">
  <a (click)="onClickNotshow()" class="default-btn">
    <i class='bx bx-show-alt icon-arrow before'></i>
    <span class="label">{{langAll.notshowmsg | translate}}</span>
    <i class='bx bxs-hide icon-arrow after'></i>
    <!-- <i class="bx bx-show-alt "></i> -->
  </a>
</div>
<!-- </div> -->