import { HttpClient, HttpClientModule } from "@angular/common/http";
import { LOCALE_ID, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatRadioModule } from "@angular/material/radio";
import { BrowserModule } from "@angular/platform-browser";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { StickyNavModule } from "ng2-sticky-nav";
import { CountUpModule } from "ngx-countup";
import { LightboxModule } from "ngx-lightbox";
import { CarouselModule } from "ngx-owl-carousel-o";
import { NgxScrollTopModule } from "ngx-scrolltop";
import { TabsModule } from "ngx-tabset";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SharedDataService } from "./core/common/sharedDataService";
import { LanguageService } from "./core/services/languageService/language.service";
import { AuthLayoutComponent } from "./layout/app-layout/auth-layout/auth-layout.component";
import { MainLayoutComponent } from "./layout/app-layout/main-layout/main-layout.component";
import { ErrorPageComponent } from "./layout/error-page/error-page.component";
import { FooterComponent } from "./layout/footer/footer.component";
import { HeaderStyleOneComponent } from "./layout/header-style-one/header-style-one.component";
import { HeaderStyleTwoComponent } from "./layout/header-style-two/header-style-two.component";
import { EvenPopupComponent } from "./modules/even-popup/even-popup.component";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderStyleTwoComponent,
    HeaderStyleOneComponent,
    FooterComponent,
    ErrorPageComponent,
    MainLayoutComponent,
    AuthLayoutComponent,
    EvenPopupComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CarouselModule,
    CountUpModule,
    TabsModule,
    NgxScrollTopModule,
    StickyNavModule,
    LightboxModule,
    FormsModule,
    MatDialogModule,
    MatRadioModule,
    MatMenuModule,
    MatProgressBarModule,
    HttpClientModule,
    MatFormFieldModule,
    Ng2SearchPipeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgIdleKeepaliveModule.forRoot(),
  ],
  providers: [SharedDataService, LanguageService],
  bootstrap: [AppComponent]
})
export class AppModule { }
