<app-header-style-two></app-header-style-two>
<br>
<br>
<br>
<br>


<div class="error-404-area">
    <div class="container">
        <div class="notfound">
            <div class="notfound-bg">
                <div></div>
                <div></div>
                <div></div>
            </div>
            <h1>อุ๊ป!</h1>
            <h3>ข้อผิดพลาดรหัส 404 : ไม่เจอหน้าที่คุณต้องการค้นหา</h3>
            <p>หน้าที่คุณต้องการค้นหาไม่สามารถใช้งานได้</p>
            <a routerLink="/" class="default-btn"><i class='bx bx-home-circle icon-arrow before'></i><span class="label">กลับสู่ หน้าหลัก</span><i class="bx bx-home-circle icon-arrow after"></i></a>
        </div>
    </div>
</div>

<app-footer></app-footer>