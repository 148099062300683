import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ExamPlacementTestInterface } from '../model/exam_placement_test.model';

@Injectable()
export class SharedDataService {

  private dataSource = new BehaviorSubject<any>('');
  getDataUserLogin = this.dataSource.asObservable();

  private dataSourceCourse = new BehaviorSubject<any>('');
  getDataCourse = this.dataSourceCourse.asObservable();

  private dataSourceAboutMe = new BehaviorSubject<any>('');
  getDataAboutMe = this.dataSourceAboutMe.asObservable();

  private dataSourceNews = new BehaviorSubject<any>('');
  getDataNews = this.dataSourceNews.asObservable();

  private dataSourceContact = new BehaviorSubject<any>('');
  getDataContact = this.dataSourceContact.asObservable();

  private dataSourceDocument = new BehaviorSubject<any>('');
  getDataDocument = this.dataSourceDocument.asObservable();

  private dataCountCart = new BehaviorSubject<any>('');
  getCountCart = this.dataCountCart.asObservable();

  private dataSearchCount = new BehaviorSubject<any>('');
  getSearchCount = this.dataSearchCount.asObservable();

  compHeaderValueLanguageBS = new BehaviorSubject<string>('');
  languageValue = this.compHeaderValueLanguageBS.asObservable();

  private dataExamLevelTest = new BehaviorSubject<ExamPlacementTestInterface[]>([]);
  getExamLevelTest = this.dataExamLevelTest.asObservable();

  constructor() { }

  updateCompcompHeaderValLanguage(val) {
    this.compHeaderValueLanguageBS.next(val);
  }

  changeDataUserLogin(val: any) {
    this.dataSource.next(val)
  }

  changeDataCourse(val: any) {
    this.dataSourceCourse.next(val)
  }

  changeDataAboutMe(val: any) {
    this.dataSourceAboutMe.next(val)
  }

  changeDataNews(val: any) {
    this.dataSourceNews.next(val)
  }

  changeDataContact(val: any) {
    this.dataSourceContact.next(val)
  }

  changeDataDocument(val: any) {
    this.dataSourceDocument.next(val)
  }

  changeCountCart(val: any) {
    this.dataCountCart.next(val)
  }

  changeSearchCount(val: any) {
    this.dataSearchCount.next(val)
  }

  changeDataExamlevelTest(val: ExamPlacementTestInterface[]) {
    this.dataExamLevelTest.next(val)
  }

}