import { Injectable } from '@angular/core';
import { Constants } from '../../constants/constant';
import { AuthenInterface } from '../../interface/auth-interface/authen-interface';
import { RegisterStudent, Student } from '../../model/register_student.model';
import { ManagerService } from '../manager.service';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  constructor(private managerService: ManagerService) { }


  AuthenLogin(dataLogin: AuthenInterface) {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/authenticate`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_POST_AUTH(API_URL, dataLogin).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  checkLogin() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Auth`;
    return new Promise((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }
  GetDataRoleAll() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Role/GetDataRoleAll`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  GenVisitor() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Visitor/GenVisitor`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }
  GetVisitor() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Visitor/GetVisitor`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

}
