import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthServiceService } from "../services/auth/auth-service.service";

// import { AuthService } from "../service/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private _router: Router,private authServiceService: AuthServiceService) { }  
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {  
      
    if (!localStorage.getItem('currentUserLogin')) {  
        return true;  
    }  
    this._router.navigate(['main']);  
    return false;  
}  

}
