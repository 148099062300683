import { Injectable } from '@angular/core';
import { Constants } from '../../constants/constant';
import { CheckStudentBuyCoursesModel } from '../../model/check_student_buycourses.model';
import { ResponseFindLearnStudentModel } from '../../model/response_find_learn_student.model';
import { ResponseFindThreadsModel } from '../../model/response_find_threads.model';
import { ManagerService } from '../manager.service';

@Injectable({
  providedIn: 'root'
})
export class StudentServiceService {

  constructor(private managerService: ManagerService) { }

  findDataStudent(studentID: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/FindDataStudent?studentID=${studentID}`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  FindDataUser(UserID: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/FindDataUser?UserID=${UserID}`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  findDataAllCourse() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Subject/GetAllSubject`;
    return new Promise((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  findDataAddress() {
    let API_URL = `${Constants.REST_API_ADDRESS}/api/ProvinceDistrictSubDistrict/dashboard/GetProvinceDropdownSelect`;
    return new Promise((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  findDataAddressDistrict(idprovince: any) {
    let API_URL = `${Constants.REST_API_ADDRESS}/api/ProvinceDistrictSubDistrict/dashboard/GetDistrictDropdownSelect?idDistrict=${idprovince}`;
    return new Promise((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  findDataAddressSubDistrict(idsubDistrict: any) {
    let API_URL = `${Constants.REST_API_ADDRESS}/api/ProvinceDistrictSubDistrict/dashboard/GetSubDistrictDropdownSelect?idSubDistrict=${idsubDistrict}`;
    return new Promise((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }
  findDataAddressProvince() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Address/Provinces`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }
  findDataDistrict(idprovince: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Address/Amphures?idProvinces=${idprovince}`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }
  findDataSubDistrict(idsubDistrict: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Address/Tambons?idAmphures=${idsubDistrict}`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }
  findDataPostCode(idTambons: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Address/ZipCode?idTambons=${idTambons}`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  GetDepartmentList() {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/GetDepartmentList`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }
  GetDepartmentList_() {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/GetDepartmentList`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  RegisterStudent(studentregisterdata: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/register`;
    return new Promise((resolve, reject) => {
      this.managerService.HTTP_POST_AUTH(API_URL, studentregisterdata).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  uploadFiles(files: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/UploadMedia/uploadFile`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_POST_IMAGE(API_URL, files).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  UploadIMGArticleDoctor_(data: FormData) {
    return new Promise((resolve, reject) => {
      let API_URL = `http://releep.achatsocial.com:2077/api/telemedicine/doctor/UploadImgBackOffice`;
      this.managerService.HTTP_POST_IMAGE(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }


  UpdateDataStudent(studentUpdateData: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/User/UpdateStudent`;
    return new Promise<any>((resolve, reject) => {
      // console.log(studentUpdateData);
      this.managerService.HTTP_POST(API_URL, studentUpdateData).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  AddCourseRating(CourseRatingData: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Rating/InsertCommentAndRating`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_POST(API_URL, CourseRatingData).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  findDataCourseRating(subjectId: String) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Rating/FindCommentAndRating?subjectID=${subjectId}`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  deleteRatingComment(ratingCommentId: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Rating/RemoveCommentAndRating?ratingID=${ratingCommentId}`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  doLikeComment(likeData: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Rating/LikeComment`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_POST(API_URL, likeData).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  feedLikeComment(ratingCommentId: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Rating/FeedLikeCommentByIdRating?ratingID=${ratingCommentId}`;
    return new Promise((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  GetFetchDataAboutMe() {
    let API_URL = `${Constants.REST_API_MAIN}/api/AboutMe/GetAboutMeList`;
    return new Promise((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  GetFetchDataNews() {
    let API_URL = `${Constants.REST_API_MAIN}/api/PlaCard/GetPlaCardList`;
    return new Promise((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  GetFetchDataContact() {
    let API_URL = `${Constants.REST_API_MAIN}/api/ContactUs/GetContactUsList`;
    return new Promise((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  GetCheckStudentBuyCourse(subjectID: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Cart/GetCheckStudentBuyCourse?subjectID=${subjectID}`;
    return new Promise<CheckStudentBuyCoursesModel>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  getFetchDataRelateOrg() {
    let API_URL = `${Constants.REST_API_MAIN}/api/RelatedOrg/GetRelatedOrgList`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  FindBank() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Bank/FindBank`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  GetFecthDataSiteRef() {
    let API_URL = `${Constants.REST_API_MAIN}/api/SiteReference/GetSiteReferenceList`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  FindMemberDetails() {
    let API_URL = `${Constants.REST_API_MAIN}/api/AboutMe/FindMemberDetails`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  GetFecthDataFindCourseSubjectByStudent(studentID: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Subject/FindCourseSubjectByStudent?studentID=${studentID}`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  InsertLearnStudent(subjectId: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Learn/InsertLearnStudent?subjectId=${subjectId}`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  FindLearnStudent(subjectId: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Learn/FindLearnStudent?subjectId=${subjectId}`;
    return new Promise<ResponseFindLearnStudentModel>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {

        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  SetLearnedStatus(learnId: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Learn/UpdateLearnStudent?learnId=${learnId}&status=true`;
    return new Promise<ResponseFindLearnStudentModel>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {

        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  GetVideoTimeLearned(contentId: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Learn/GetLearnVideoTime?contentId=${contentId}`;
    return new Promise<ResponseFindLearnStudentModel>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  UpdateVideoTimeLearned(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Learn/UpdateLearnVideoTime`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  findThreads() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/FindThreads`;
    return new Promise<ResponseFindThreadsModel>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {

        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  insertThreadsLikeAndRemove(likeData: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/InsertThreadsLikeAndRemove`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_POST(API_URL, likeData).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  removeThreads(threadId: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/RemoveThreads?threadId=${threadId}`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  category() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Subject/Category`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  insertThreads(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/InsertThreads`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  updateThreads(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/UpdateThreads`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  addCommentsThreads(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/InsertThreadsComment`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  findCommentsThreads(threadsId: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/FindThreadsComment?threadsId=${threadsId}`;
    return new Promise<ResponseFindThreadsModel>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  removeCommentsThreads(commentId: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/RemoveThreadsComment?thdMentId=${commentId}`;
    return new Promise<ResponseFindThreadsModel>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }


  updateCommentsThreads(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/UpdateThreadsComment`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  updateGetAnswer(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Threads/UpdateThreadsCommentGetAnswer`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  getPlaCardList() {
    let API_URL = `${Constants.REST_API_MAIN}/api/PlaCard/GetPlaCardList`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  countSubjectCategory() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Subject/CountSubjectByCategory`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  findAllStudentCertificate(studentId: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Certificate/FindCertificateStudent?studentId=${studentId}`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  insertStudentCertificate(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Certificate/InsertCertificateByStudent`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  getManualList() {
    let API_URL = `${Constants.REST_API_MAIN}/api/UserManual/GetUserManualList`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  getSatisfiedQuestion() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Satisfind/FindSatisfindQuestionAndScore`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  insertStudentSatisfiedQuestion(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Satisfind/InsertSatisfactionQuestionnaire`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  checkSaatisfiedStatus(subjectId: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Satisfind/FindCheckSatisfactionQuestionnaire?subjectId=${subjectId}`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  insertBugReport(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Report/InsertReportProblem`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  InsertLogLearnAndUpdate(subjectId: string, lessionId: string, status: string) {
    let API_URL = `${Constants.REST_API_MAIN}/api/Learn/InsertLogLearnAndUpdate?subjectId=${subjectId}&lessionId=${lessionId}&status=${status}`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  GetDataRoleAll() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Role/GetDataRoleAll`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  /////OTP////
  GenarateToken(Email: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/ResetPassword/GenarateToken?Email=${Email}`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  CheckOtpAndEmail(data: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/ResetPassword/CheckOtpAndEmail`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_POST(API_URL, data).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  NewPasswordRegister(id: any, password: any) {
    let API_URL = `${Constants.REST_API_MAIN}/api/ResetPassword/NewPasswordRegister?id=${id}&studentPassword=${password}`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

  GetPortalType() {
    let API_URL = `${Constants.REST_API_MAIN}/api/Role/GetPortalType`;
    return new Promise<any>((resolve, reject) => {
      this.managerService.HTTP_GET(API_URL).then(response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    });
  }

}

