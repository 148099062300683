import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ErrorPageComponent } from './layout/error-page/error-page.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { AuthGuard } from './core/guard/auth.guard';


const routes: Routes = [
  {
    path: "",
    component: MainLayoutComponent,
    // canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "/main", pathMatch: "full" },
      {
        path: "main",
        // canActivate: [AuthGuard],
        loadChildren: () =>
          import("./modules/main/main.module").then((m) => m.MainModule),
      },
      {
        path: "about",
        // canActivate: [AuthGuard],
        loadChildren: () =>
          import("./modules/about/about.module").then((m) => m.AboutModule),
      },
      {
        path: "news",
        // canActivate: [AuthGuard],
        loadChildren: () =>
          import("./modules/news/news.module").then((m) => m.NewsModule),
      },
      {
        path: "course",
        // canActivate: [AuthGuard],
        loadChildren: () =>
          import("./modules/course/course.module").then((m) => m.CourseModule),
      },
      {
        path: "cart",
        // canActivate: [AuthGuard],
        loadChildren: () =>
          import("./modules/cart/cart.module").then((m) => m.CartModule),
      },
      {
        path: "questions",
        // canActivate: [AuthGuard],
        loadChildren: () =>
          import("./modules/questions/questions.module").then((m) => m.QuestionsModule),
      },
      {
        path: "mamual",
        // canActivate: [AuthGuard],
        loadChildren: () =>
          import("./modules/mamual/mamual.module").then((m) => m.MamualModule),
      },
      {
        path: "contact",
        // canActivate: [AuthGuard],
        loadChildren: () =>
          import("./modules/contact/contact.module").then((m) => m.ContactModule),
      },
      {
        path: "profile",
        // canActivate: [AuthGuard],
        loadChildren: () =>
          import("./modules/profile/profile.module").then((m) => m.ProfileModule),
      },
      {
        path: "report-problem",
        // canActivate: [AuthGuard],
        loadChildren: () =>
          import("./modules/report-problem/report-problem.module").then((m) => m.ReportModule),
      },
    ],
  },
  {
    path: "authentication",
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    loadChildren: () =>
      import("./modules/auth/auth.module").then(
        (m) => m.AuthModule
      ),
  },
  { path: "**", component: ErrorPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }