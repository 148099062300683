import { LanguageInterfaceAll } from "./language-interface-all";

export const LanguageAll: LanguageInterfaceAll[] = [
  {
    // Main
    course: "MENU.COURSE",
    allcourses: "COURSE.MAIN.COURSEALL",
    free: "COURSE.MAIN.FREE",
    classlist: "ALL.MAIN.CLASSLIST",
    document: "ALL.MAIN.DOCUMENT",
    documentpublic: "ALL.MAIN.DOCUMENTPUBLIC",
    documentpublicall: "ALL.MAIN.DOCUMENTPUBLICALL",
    documentpubliclatest: "ALL.MAIN.DOCUMENTPUBLICLATEST",
    coursescompleted: "COURSE.MAIN.COURSES-COMPLETED",
    coursebeingstudied: "COURSE.MAIN.COURSE-BEING-STUDIED",
    coursesnotyetstudied: "COURSE.MAIN.COURSES-NOT-YET-STUDIED",
    downloaddoc: "ALL.MAIN.DOWNLOADDOC",
    nofile: "ALL.MAIN.NOFILE",
    desc: "ALL.MAIN.DESC",
    cgr: "ALL.Q&A.CATEGORY",
    seeallcgr: "ALL.MAIN.SEEALLCGR",
    cgrcourse: "ALL.MAIN.CGRCOURSE",
    agency: "ALL.MAIN.AGENCY",
    notshowmsg: "ALL.MAIN.NOTSHOWMSG",
    knowledge: "ALL.MAIN.KNOWLEDGE",
    future: "ALL.MAIN.FUTURE",
    tothegoal: "ALL.MAIN.TOTHEGOAL",
    longmsg: "ALL.MAIN.LONGMSG",
    coursebycgr: "ALL.MAIN.COURSEBYCGR",
    ministryofargiculater: "ALL.MAIN.MINISTRY-OF-AGRICULTURE",
    thetest: "COURSE.LEARN.THETEST",
    websitevisitors: "ALL.MAIN.WEBSITE-VISITORS",
    total: "ALL.MAIN.TOTAL",
    today: "ALL.MAIN.TODAY",
    yesterday: "ALL.MAIN.YESTERDAY",
    thismonth: "ALL.MAIN.THISMONTH",

    //Dialog
    cancelask: "DIALOG.CANCELASK",
    yes: "DIALOG.YES",
    no: "DIALOG.NO",
    comask: "DIALOG.COMASK",
    thank: "DIALOG.THANKS",
    attachpic: "DIALOG.ATTACHPIC",
    fillall: "DIALOG.FILL",
    nocart: "DIALOG.NOCART",
    goback: "DIALOG.GOBACK",
    notest: "DIALOG.NOTEST",
    yesdel: "DIALOG.YESDEL",
    yesupdate: "DIALOG.YESUPDATE",
    checkalltab: "DIALOG.CHECKALLTAB",
    closepretest: "DIALOG.CLOSEPRETEST",
    notime: "COURSE.TEST.TIMEUP",
    pretestagain: "DIALOG.PRETESTAGAIN",
    canceltest: "DIALOG.CANCELTEST",
    okfinished: "DIALOG.OKFINISHED",
    thisisyouranswer: "DIALOG.THISISYOURANSWER",
    surecancelyouranswer: "DIALOG.SURECANCELYOURANSWER",
    warning: "DIALOG.WARNING",
    nodopre: "DIALOG.NODOPRE",
    doyoustudying: "DIALOG.DOYOUSTUDYING",
    goodluck: "DIALOG.GOODLUCK",
    wantdopost: "DIALOG.WANTDOPOST",
    skiplesson: "DIALOG.SKIPLESSON",
    mustdoforskip: "DIALOG.MUSTDOFORSKIP",
    confirmforskip: "DIALOG.CONFIRMFORSKIP",
    confirmdoagain: "DIALOG.CONFIRMDOAGAIN",
    passlesson: "DIALOG.PASSLESSON",
    faillesson: "DIALOG.FAILLESSON",
    posttestagain: "DIALOG.POSTTESTAGAIN",
    closepost: "DIALOG.CLOSEPOST",
    sentanswer: "DIALOG.SENTANSWER",
    waitteacher: "DIALOG.WAITTEACHER",
    startagain: "DIALOG.STARTAGAIN",
    viewscore: "DIALOG.VIEWSCORE",
    youhavepassed: "DIALOG.YOUHAVEPASSED",
    youcorrect: "DIALOG.YOUCORRECT",

    // Report
    tellus: "ALL.REPORT.TELLUS",
    correctheading: "ALL.REPORT.CORRECTHEADING",
    correctdesc: "ALL.REPORT.CORRECTDESC",
    sendproblem: "ALL.REPORT.SENDPROBLEM",

    // About
    home: "MENU.HOME",
    about: "MENU.ABOUT",
    aboutus: "ALL.ABOUT.ABOUTUS",
    n_teacher: "ALL.ABOUT.NTEACHER",
    n_course: "ALL.ABOUT.NCOURSE",
    n_student: "ALL.ABOUT.NSTUDENT",
    n_document: "ALL.ABOUT.NDOCUMENT",
    tobestudent: "ALL.ABOUT.TOBESTUDENT",
    descstudy: "ALL.ABOUT.DESCSTUDY",
    goal: "ALL.ABOUT.GOAL",
    webetter: "ALL.ABOUT.WEBETTER",
    seeallcourses: "ALL.ABOUT.SEEALLCOURSES",

    // News
    news: "MENU.MESSAGE",
    latestnews: "ALL.NEWS.LATESTNEWS",
    resultsearch: "COURSE.MAIN.RESULTSEARCH",
    nosearch: "COURSE.MAIN.NOSEARCH",
    readmore: "ALL.NEWS.READMORE",
    post: "ALL.NEWS.POST",

    // Q&A
    qa: "MENU.Q&A",
    qageneral: "ALL.Q&A.QAGENERAL",
    askquest: "ALL.Q&A.ASKQUEST",
    search: "COURSE.MAIN.SEARCH",
    general: "ALL.Q&A.GENERAL",
    admin: "ALL.Q&A.ADMIN",
    edit: "ALL.Q&A.EDIT",
    deletepost: "ALL.Q&A.DELETEPOST",
    like: "ALL.Q&A.LIKE",
    answer: "ALL.Q&A.ANSWER",
    cancel: "ALL.Q&A.CANCEL",
    faq: "ALL.Q&A.FAQ",
    faqcategory: "ALL.Q&A.FAQCATEGORY",
    latestqageneral: "ALL.Q&A.LATESTQAGENERAL",
    gotanswer: "ALL.Q&A.GOTANSWER",
    noanswer: "ALL.Q&A.NOANSWER",
    postauthor: "ALL.Q&A.POSTAUTHOR",
    category: "ALL.Q&A.CATEGORY",
    postdate: "ALL.Q&A.POSTDATE",
    n_like: "ALL.Q&A.NONUMBERLIKE",
    n_nolike: "ALL.Q&A.NOLIKE",
    all: "ALL.Q&A.ALL",
    answerquest: "ALL.Q&A.ANSWERQUEST",
    selectpic: "ALL.Q&A.SELECTPIC",
    comment: "ALL.Q&A.COMMENT",
    confirmanswerquest: "ALL.Q&A.CONFIRMANSWERQUEST",
    deleteanswer: "ALL.Q&A.DELETEANSWER",
    people: "ALL.Q&A.PEOPLE",
    wantqa: "ALL.Q&A.WANTQA",
    confirmwantqa: "ALL.Q&A.CONFIRMWANTQA",
    cancelwantqa: "ALL.Q&A.CANCELWANTQA",
    askhere: "ALL.Q&A.ASKHERE",
    qatype: "ALL.Q&A.QATYPE",
    pleaseqatype: "ALL.Q&A.PLEASEQATYPE",
    qaheading: "ALL.Q&A.QAHEADING",
    qadesc: "ALL.Q&A.QADESC",
    confirmqa: "ALL.Q&A.CONFIRMQA",
    fillallform: "ALL.Q&A.FILLALLFORM",

    // Usability
    use: "MENU.USE",
    manual: "ALL.USABILITY.MANUAL",
    clickuse: "ALL.USABILITY.CLICKUSE",
    howtouse: "ALL.USABILITY.HOWTOUSE",

    // Header (Menu)
    nodata: "CONTACT.NODATA",
    problemreport: "ALL.HEADER.PROBLEMREPORT",
    reportyourproblem: "ALL.HEADER.REPORTYOURPROBLEM",
    email: "ALL.HEADER.EMAIL",
    contacta: "ALL.HEADER.CONTACTA",
    signin: "ALL.HEADER.SIGNIN",
    loading: "ALL.HEADER.LOADING",
    profile: "ALL.HEADER.PROFILE",
    yourorder: "ALL.HEADER.YOURORDER",
    allyourcourses: "ALL.HEADER.ALLYOURCOURSES",
    yourcertificate: "ALL.HEADER.YOURCERTIFICATE",
    signout: "ALL.HEADER.SIGNOUT",
    contactus: "MENU.CONTACTUS",
    leveltest: "COURSE.TEST.LEVEL-TEST",

    th: "MENU.TH",
    en: "MENU.EN",

    // Profile
    s_email: "ALL.PROFILE.EMAIL",
    s_phone: "ALL.PROFILE.PHONE",
    citizenid: "ALL.PROFILE.CITIZENID",
    studentinfo: "ALL.PROFILE.STUDENTINFO",
    generalinfo: "ALL.PROFILE.GENERALINFO",
    nameeng: "ALL.PROFILE.NAMEENG",
    nameth: "ALL.PROFILE.NAMETH",
    sex: "ALL.PROFILE.SEX",
    birthday: "ALL.PROFILE.BIRTHDAY",
    save: "ALL.PROFILE.SAVE",
    addressinfo: "ALL.PROFILE.ADDRESSINFO",
    currentaddress: "ALL.PROFILE.CURRENTADDRESS",
    canceledit: "ALL.PROFILE.CANCELEDIT",
    province: "ALL.PROFILE.PROVINCE",
    district: "ALL.PROFILE.DISTRICT",
    subdistrict: "ALL.PROFILE.SUBDISTRICT",
    postalcode: "ALL.PROFILE.POSTALCODE",
    pleasefilladdress: "ALL.PROFILE.PLEASEFILLADDRESS",

    // Your Certificates
    allcertificates: "ALL.CERTIFICATES.ALL",
    viewcertificates: "ALL.CERTIFICATES.VIEW",
    certificatesid: "ALL.CERTIFICATES.ID",
    certificatesguarantee: "ALL.CERTIFICATES.GUARANTEE",

    // All Your Courses
    sortby: "COURSE.MAIN.ARRANGEBY",
    latest: "COURSE.MAIN.LATEST",
    student: "COURSE.MAIN.STUDENT",
    noscore: "COURSE.MAIN.NOSCORE",
    lesson: "COURSE.MAIN.LESSON",
    progress: "COURSE.MAIN.PROGRESS",
    score: "COURSE.MAIN.SCORE",
    review: "COURSE.MAIN.REVIEW",
    noprogress: "COURSE.MAIN.NOPROGRESS",
    progressing: "COURSE.MAIN.PROGRESSING",
    finished: "COURSE.MAIN.FINISHED",
    studyprogress: "COURSE.DETAIL.STUDYPROGRESS",
    waitresult: "COURSE.DETAIL.WAITRESULT",
    nocertificate: "COURSE.DETAIL.NOCERTIFICATE",

    // Order
    checkorder: "ALL.ORDER.CHECKORDER",
    cancelonly: "ALL.Q&A.CANCEL",
    payment: "ALL.ORDER.PAYMENT",
    complete: "ALL.ORDER.COMPLETE",
    ticket: "ALL.ORDER.TICKET",
    gotfree: "ALL.ORDER.GOTFREE",
    creditdebit: "ALL.ORDER.CREDITDEBIT",
    internetbanking: "ALL.ORDER.INTERNETBANKING",
    banktransfer: "ALL.ORDER.BANKTRANSFER",
    inprogress: "ALL.ORDER.INPROGRESS",

    // Cart
    yourcart: "ALL.CART.YOURCART",
    teacher: "ALL.CART.TEACHER",
    baht: "COURSE.MAIN.BAHT",
    price: "COURSE.MAIN.PRICE",
    coursename: "ALL.CART.COURSENAME",
    lessonqty: "ALL.CART.LESSONQTY",
    pay: "ALL.CART.PAY",
    summary: "ALL.CART.SUMMARY",
    discount: "ALL.CART.DISCOUNT",
    prediscount: "ALL.CART.PREDISCOUNT",
    postdiscount: "ALL.CART.POSTDISCOUNT",
    paymentinfo: "ALL.CART.PAYMENTINFO",
    paysuccess: "ALL.CART.PAYSUCCESS",
    payfail: "ALL.CART.PAYFAIL",
    paywait: "ALL.CART.PAYWAIT",
    selectcourse: "ALL.CART.SELECTCOURSE",
    seemorecourse: "ALL.CART.SEEMORECOURSE",
    attachslip: "ALL.CART.ATTACHSLIP",
    amount: "ALL.CART.AMOUNT",
    fill: "ALL.CART.FILL",
    transferloading: "ALL.CART.TRANSFERLOADING",
    confirmpayment: "ALL.CART.CONFIRMPAYMENT",
    transferinfo: "ALL.CART.TRANSFERINFO",
    bank: "ALL.CART.BANK",
    accnumber: "ALL.CART.ACCNUMBER",
    accname: "ALL.CART.ACCNAME",
    checkamount: "ALL.CART.CHECKAMOUNT",
    transfertoacc: "ALL.CART.TRANSFERTOACC",
    selectbank: "ALL.CART.SELECTBANK",

    studentp: "ALL.CART.STUDENT",
    guest: "ALL.CART.GUEST",

    position: "SIGNUP.POSITION",
    department: "SIGNUP.SELECTDEPARTMENT",
    division: "SIGNUP.DIVISION-OFFICE",
    ministry: "SIGNUP.MINISTRY",
  }
];
