import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Constants } from 'src/app/core/constants/constant';
import { StudentServiceService } from 'src/app/core/services/student/student-service.service';
import { SharedDataService } from 'src/app/core/common/sharedDataService';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/core/services/languageService/language.service';
import { LanguageAll } from '../language-interface/language-item-all'

@Component({
  selector: 'app-even-popup',
  templateUrl: './even-popup.component.html',
  styleUrls: ['./even-popup.component.scss']
})
export class EvenPopupComponent implements OnInit {

  langAll: any;

  constant: string = Constants.REST_API_MAIN.toString();
  imgBanner = []
  homeSlides: OwlOptions = {
    loop: true,
    nav: true,
    dots: true,
    autoplayHoverPause: true,
    autoplay: true,
    items: 1,
    autoHeight: true,
    responsive: {
      0: {
        items: 1
      },
      480: {
        items: 1
      },
      600: {
        items: 1
      },
      1000: { items: 1 }
    },
    navText: [
      "<i class='bx bx-chevron-left'></i>",
      "<i class='bx bx-chevron-right'></i>"
    ]
  }
  constructor(
    private studentService: StudentServiceService,
    public dialogRef: MatDialogRef<EvenPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public translate: TranslateService,
    public languageService: LanguageService,
    private sharedDataService: SharedDataService
  ) {
    this.translate.use(localStorage.getItem("lang"));

  }


  async ngOnInit() {
    this.langAll = LanguageAll[0];
    this.sharedDataService.languageValue.subscribe((message) => {
      if (message != "")
        this.translate.use(message);
    });
    this.loadDataBanner();
  }

  loadDataBanner() {
    this.data.forEach(element => {
      if (element.status && element.typePlubic == "even popup") {
        var imgArray = element.image.split(",");
        imgArray.forEach(elementIMG => {
          var path2 = elementIMG.replace(/\\/g, "/");
          this.imgBanner = this.imgBanner.concat(this.constant + path2);
        });

      }
    });
  }

  onClickNotshow() {
    var date = new Date;
    localStorage.setItem("showbanner", date.toString());
    this.dialogRef.close();
  }

}
